<template>
  <v-data-table
    :headers="headers"
    :items="trafficItems"
    :search="search"
    :loading="trafficChartIsLoading"
    :options.sync="options"
    class="table-container"
    hide-default-footer
    @page-count="pageCount = $event"
  >
    <template #top>
      <div class="black d-flex justify-space-between align-center py-2 pr-3 pl-6">
        <div class="white--text font-weight-bold title mr-3">
          {{ $t('traffic_chart.table_name') }}
        </div>
        <v-text-field
          v-model="search"
          :label="$t('traffic_chart.search_table')"
          single-line
          hide-details
          solo
          dense
          class="width-400"
        />
      </div>
      <group-letter-buttons @change-letter="changeSortByLetter($event)" />
      <v-divider />
    </template>
    <template #item.actions="{ item }">
      <c-btn
        text
        small
        class="primary"
        :label="$t('main.create_campaign')"
        @click="createCampaignWithParameters(item)"
      />
    </template>
    <template #item.min_price="{ item }">
      {{ toFixedByDefault(item.min_price, 4) }}
    </template>
    <template #item.recommended_price="{ item }">
      {{ toFixedByDefault(item.recommended_price, 4) }}
    </template>
    <template #footer>
      <data-table-footer
        :page-count="pageCount"
        :items-per-page="options.itemsPerPage"
        :current-page="options.page"
        @change-items-per-page="changePaginator($event, 'itemsPerPage')"
        @change-current-page="changePaginator($event, 'page')"
      />
    </template>
  </v-data-table>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import GroupLetterButtons from '@/views/TrafficChart/TrafficTable/GroupLetterButtons.vue'
  import routeNames from '@/types/route-names.js'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'TrafficTable',
    components: {
      DataTableFooter,
      GroupLetterButtons,
      CBtn
    },
    data() {
      return {
        search: '',
        sortByLetter: '',
        pageCount: 0,

        options: {
          sortBy: [],
          sortDesc: [],
          page: 1,
          itemsPerPage: 15
        }
      }
    },
    computed: {
      ...mapState('trafficChart', ['trafficChartIsLoading', 'traffic']),
      ...mapGetters('trafficChart', ['foundAdFormat']),
      trafficItems() {
        if (this.sortByLetter) {
          return this.traffic.filter(
            (item) => item.country_name[0].toUpperCase() === this.sortByLetter
          )
        }
        return this.traffic
      },
      isImpressionShown() {
        const adFormatsType = [
          adFormats.POPUNDER,
          adFormats.BANNER,
          adFormats.WEB_PUSH,
          adFormats.IN_STREAM_AD,
          adFormats.NATIVE_AD,
          adFormats.DIRECT_LINK,
          adFormats.INTERSTITIAL,
          adFormats.IN_PAGE_AD
        ]
        return this.foundAdFormat ? adFormatsType.includes(this.foundAdFormat.type) : true
      },
      AreClicksShown() {
        const adFormatsType = [
          adFormats.IOS_CALENDAR,
          adFormats.GALLERY,
          adFormats.BANNER,
          adFormats.WEB_PUSH,
          adFormats.IN_STREAM_AD,
          adFormats.NATIVE_AD,
          adFormats.DIRECT_LINK,
          adFormats.INTERSTITIAL,
          adFormats.IN_PAGE_AD
        ]
        return this.foundAdFormat ? adFormatsType.includes(this.foundAdFormat.type) : true
      },
      headers() {
        const activeHeaders = [{ value: 'country_name', text: this.$t('traffic_chart.country'), width: '20%' }]
        if (this.isImpressionShown) {
          activeHeaders.push({ value: 'impressions', text: this.$t('traffic_chart.total_impressions') })
        }
        if (this.AreClicksShown) {
          activeHeaders.push({ value: 'clicks', text: this.$t('traffic_chart.total_clicks') })
        }
        activeHeaders.push(
          { value: 'min_price', text: `${this.$t('traffic_chart.min_price')} $` },
          { value: 'recommended_price', text: `${this.$t('traffic_chart.recommended_price')} $` },
          { value: 'actions', width: 30, sortable: false }
        )
        return activeHeaders
      }
    },
    methods: {
      ...mapActions('trafficChart', ['setCountryPreset']),
      changePaginator(val, key) {
        this.options[key] = val
        if (key === 'itemsPerPage') {
          this.options.page = 1
        }
      },
      changeSortByLetter(event) {
        this.sortByLetter = event
        this.currentPage = 1
      },
      createCampaignWithParameters(selectedPreset) {
        this.setCountryPreset(selectedPreset)

        gtmPush({
          event: GTM_EVENTS.TRAFFIC_CHART_BUTTON_CREATE_CAMPAIGN
        })

        this.$router.push({
          name: routeNames.CREATE_CAMPAIGN,
          query: {
            trafficChartsPresets: true
          }
        })
      },
      toFixedByDefault
    }
  }
</script>

<style lang="scss" scoped>
  .width-400 {
    max-width: 400px;
  }
  .table-container {
    border-radius: 4px;
    overflow: hidden;
  }
</style>
